<template lang="pug">
section.our-story
  .our-story__wrapper.container.is-flex
    .our-story__left
      v-image.our-story__left--image(src="homePage/ourHistory/left@2x.png")
    .our-story__right
      .our-story__right-wrapper
        .our-story__right--sub-title {{ $t('homePage.ourHistory.subTitle') }}
        .our-story__right--title {{ $t('homePage.ourHistory.title') }}
        .our-story__right--text {{ $t('homePage.ourHistory.text') }}
        v-link.our-story__right--more(:to="{ name: 'education-index-category', params: { category: 'our-history' } }") {{ $t('homePage.ourHistory.readMore') }}
        v-image.our-story__right--image(src="homePage/ourHistory/right@2x.png", :alt="$t('homePage.ourHistory.subTitle')")
</template>

<script>
export default {
  name: 'OurStoryComponent'
}
</script>

<style lang="sass" scoped>
.our-story
  background-color: $secondary-bg-color
  margin-top: 127px
  margin-bottom: 100px
  +touch
    margin-top: 202px
  +mobile
    margin-top: 167px
  &__wrapper
    column-gap: 73px
    +touch
      flex-direction: column
      column-gap: 0
  &__right,
  &__left
    width: 50%
    position: relative
  &__left
    +touch
      width: 100%
  &__right
    padding: 120px 0
    +touch
      width: 100%
      padding: 150px 0 120px
    +mobile
      padding: 120px 0
  &__right-wrapper
    max-width: 541px
    width: 100%
    +touch
      max-width: 100%
  &__right--sub-title
    text-transform: uppercase
    font-size: 12px
  &__right--title
    margin-top: 20px
    font-size: 36px
    font-family: $lora
    font-style: italic
  &__right--text
    margin-top: 20px
    font-size: 16px
  &__right--more
    margin-top: 30px
    font-weight: bold
    text-decoration: underline
    font-size: 16px
  &__right--image,
  &__left--image
    position: absolute
  &__left--image
    z-index: 1
    max-width: 889px
    width: calc(495px + 394 * ((100vw - 1025px) / (1920 - 1025)))
    height: auto
    right: 0
    top: -80px
    +touch
      left: 0
      right: unset
      width: 402px
      top: -135px
      +mobile
        width: 328px
        left: 50%
        transform: translate(-50%, 0)
  &__right--image
    left: 55%
    transform: translate(-50%, 0)
    z-index: 1
    width: 396px
    height: 209px
    +touch
      right: 0
      left: unset
      transform: unset
      width: 401px
      height: 226px
    +mobile
      width: 314px
      left: 50%
      transform: translate(-50%, 0)
      bottom: -140px
</style>
